@import '@bw/bw-styles/dist/scss/importsVariables';

.admin-nav__brand-logo {
  .logo {
    height: 32px;
    width: 32px;
  }
}

.admin-nav__brand-label {
  font-size: 1.375rem;
  font-weight: 300;
}

.navbar {
  padding: 0.85rem 1.5rem;
  background-color: $color-background-interactive-primary-pressed;
}

@media (min-width: 900px) {
  .admin-nav__collapse {
    justify-content: end;
  }
}
