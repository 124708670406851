@import '@bw/bw-styles/dist/scss/importsVariables';

html,
body,
#root {
  min-height: 100vh;
}

:root {
  --mdb-body-font-size: #{$font-size-body-default};
}

#root {
  display: flex;
  flex-direction: column;
}

.centered-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  gap: 1.5rem;
}
